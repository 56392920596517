var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.hasMaintenanceAppPermission && !_vm.hasSDAppPermission ? _c('AssetStyled', [_c('Panel', {
    staticClass: "panel",
    attrs: {
      "noHeader": true
    },
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_vm.$apollo.queries.asset.loading || _vm.$apollo.queries.machineHealth.loading || _vm.$apollo.queries.assetEngines.loading ? _c('TalpaLoaderWrapper', {
          staticClass: "loader-wrapper"
        }) : [_c('AssetInfoStyled', [_c('AssetInformationCard', {
          attrs: {
            "asset": _vm.asset,
            "hasSignalReadPermission": _vm.hasSignalReadPermission,
            "hasMaintenanceAppPermission": _vm.hasMaintenanceAppPermission,
            "hasSDAppPermission": _vm.hasSDAppPermission,
            "machineHealth": _vm.combinedAssetHealth,
            "hasUptimePermission": _vm.hasUptimePermission,
            "engine": _vm.engine
          }
        }), _c('HealthAndCountsStyled', [_vm.validator ? _c('AssetHealthStatusConsole', {
          attrs: {
            "status": _vm.combinedAssetHealth
          }
        }) : _vm._e(), _vm.machineHealth.length > 0 ? _c('MachineHealthMain', {
          attrs: {
            "machineHealth": _vm.machineHealth
          }
        }) : _vm._e()], 1)], 1)]];
      },
      proxy: true
    }], null, false, 3813462204)
  }), _vm.hasSignalReadPermission && _vm.hasEngineReadPermission ? _c('ExpandableSection', {
    staticClass: "collapsablePanel",
    attrs: {
      "title": _vm.$t('engine')
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('AssetEnginePanelsOrganism', {
          staticClass: "content",
          attrs: {
            "asset": _vm.asset,
            "engineManufacturer": 'Deutz',
            "hasEnginePanelPermission": !!(_vm.hasSignalReadPermission && _vm.hasEngineReadPermission)
          }
        })];
      },
      proxy: true
    }], null, false, 2405367942)
  }) : _vm._e(), _c('ExpandableSection', {
    staticClass: "collapsablePanel",
    attrs: {
      "title": _vm.$tc('tyre', 2)
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [!_vm.hasTyreReadPermission && !_vm.isTyreNotificationAcknowledged ? _c('FeatureNotAvailable', {
          staticClass: "content",
          attrs: {
            "title": _vm.tyreNotAvailable.title,
            "message": _vm.tyreNotAvailable.message,
            "imageSrc": _vm.NoTyreFeatureImageSrc
          },
          on: {
            "accepted": _vm.acknowledgeTypeMessage
          }
        }) : _vm._e(), _c('TyreStatusTable', {
          staticClass: "content",
          attrs: {
            "route": _vm.$route
          }
        })];
      },
      proxy: true
    }], null, false, 3590333989)
  })], 1) : _c('AssetStyled', [_c('Panel', {
    staticClass: "panel",
    attrs: {
      "noHeader": true
    },
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_vm.$apollo.queries.asset.loading ? _c('TalpaLoaderWrapper', {
          staticClass: "loader-wrapper"
        }) : [_c('AssetInfoStyled', [_c('AssetInformationCard', {
          attrs: {
            "asset": _vm.asset,
            "hasSignalReadPermission": _vm.hasSignalReadPermission,
            "hasMaintenanceAppPermission": _vm.hasMaintenanceAppPermission,
            "hasSDAppPermission": _vm.hasSDAppPermission,
            "hasUptimePermission": _vm.hasUptimePermission,
            "engine": _vm.engine
          }
        })], 1)]];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }