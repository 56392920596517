<template>
  <IconWrapper>
    <TalpaIcon :isCustomColor="true" :scope="'TrafficLight'" :name="iconName" class="statusIcon" />
  </IconWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { TalpaIcon } from '@common/components'

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  > div,
  svg.customColor {
    width: 140px !important;
    height: 65px !important;
    path#backDrop {
      fill: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
    }
    circle#blank {
      fill: ${({ theme }) => chroma(theme.colors.atomic.black).alpha(0.2).css()};
    }
  }
`
export default {
  props: {
    iconName: {
      type: String,
      required: true,
    },
  },
  components: {
    TalpaIcon,
    IconWrapper,
  },
}
</script>
