<template>
  <AssetStyled v-if="!hasMaintenanceAppPermission && !hasSDAppPermission">
    <Panel :noHeader="true" class="panel">
      <template v-slot:main>
        <TalpaLoaderWrapper
          class="loader-wrapper"
          v-if="$apollo.queries.asset.loading || $apollo.queries.machineHealth.loading || $apollo.queries.assetEngines.loading"
        />
        <template v-else>
          <AssetInfoStyled>
            <AssetInformationCard
              :asset="asset"
              :hasSignalReadPermission="hasSignalReadPermission"
              :hasMaintenanceAppPermission="hasMaintenanceAppPermission"
              :hasSDAppPermission="hasSDAppPermission"
              :machineHealth="combinedAssetHealth"
              :hasUptimePermission="hasUptimePermission"
              :engine="engine"
            />
            <HealthAndCountsStyled>
              <AssetHealthStatusConsole v-if="validator" :status="combinedAssetHealth" />
              <MachineHealthMain v-if="machineHealth.length > 0" :machineHealth="machineHealth" />
            </HealthAndCountsStyled>
          </AssetInfoStyled>
        </template>
      </template>
    </Panel>
    <ExpandableSection :title="$t('engine')" class="collapsablePanel" v-if="hasSignalReadPermission && hasEngineReadPermission">
      <template v-slot:content>
        <AssetEnginePanelsOrganism
          class="content"
          :asset="asset"
          :engineManufacturer="'Deutz'"
          :hasEnginePanelPermission="!!(hasSignalReadPermission && hasEngineReadPermission)"
        />
      </template>
    </ExpandableSection>
    <ExpandableSection :title="$tc('tyre', 2)" class="collapsablePanel">
      <template v-slot:content>
        <FeatureNotAvailable
          v-if="!hasTyreReadPermission && !isTyreNotificationAcknowledged"
          :title="tyreNotAvailable.title"
          :message="tyreNotAvailable.message"
          :imageSrc="NoTyreFeatureImageSrc"
          @accepted="acknowledgeTypeMessage"
          class="content"
        />
        <TyreStatusTable :route="$route" class="content" />
      </template>
    </ExpandableSection>
  </AssetStyled>
  <AssetStyled v-else>
    <Panel :noHeader="true" class="panel">
      <template v-slot:main>
        <TalpaLoaderWrapper class="loader-wrapper" v-if="$apollo.queries.asset.loading" />
        <template v-else>
          <AssetInfoStyled>
            <AssetInformationCard
              :asset="asset"
              :hasSignalReadPermission="hasSignalReadPermission"
              :hasMaintenanceAppPermission="hasMaintenanceAppPermission"
              :hasSDAppPermission="hasSDAppPermission"
              :hasUptimePermission="hasUptimePermission"
              :engine="engine"
            />
          </AssetInfoStyled>
        </template>
      </template>
    </Panel>
  </AssetStyled>
</template>

<script>
import AssetInformationCard from './AssetInformationCard'
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import MachineHealthMain from './MachineHealth/MachineHealthMain'
import { Panel, TalpaLoaderWrapper } from '@common/components'
import ExpandableSection from '@/components/Atomic/Atoms/ExpandableSection'

import ASSET_QUERY from '#/graphql/operations/asset/assetWithLastPosition.gql'
import ASSET_WITH_HEALTH_QUERY from '#/graphql/operations/asset/assetWithHealth.gql'
import ASSET_WITH_WHEELPOSITION_QUERY from '#/graphql/operations/asset/wheelposition/assetWheelPositionsMounted.gql'
import ASSET_ENGINES_QUERY from '#/graphql/assetEngines/engineDetails.gql'

import permissionsMixin from '@/mixins/permissions'

import FeatureNotAvailable from '../../../components/Atomic/Molecules/FeatureNotAvailable'
import Vue from 'vue'
import TyreStatusTable from '@/components/Atomic/Organisms/Tyres/TyreStatusTable'
import AssetEnginePanelsOrganism from '@/components/Atomic/Organisms/Asset/Engine/AssetEnginePanelsOrganism'
import AssetHealthStatusConsole from '@/components/Atomic/Molecules/AssetHealthStatusConsole'

const AssetStyled = styled('div')`
  display: grid;
  height: 100%;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;

  & > .panel {
    background: none;
    border: none;
  }

  .loader-wrapper {
    min-height: 225px;
  }
  .collapsablePanel {
    margin: 0.5rem 0;
    .title {
      font-size: 16px;
      text-transform: uppercase;
      .subtitle {
        padding-left: 0.5rem;
        color: ${({ theme }) => theme.colors.atomic.feedItemCategoryColor};
      }
    }
    .content {
      margin-top: 1rem;
    }
  }
`

const AssetInfoStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
const HealthAndCountsStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 1024px) {
    width: 100%;
    > div {
      flex-basis: 50%;
    }
  }
`

export default {
  name: 'Asset',
  inject: ['permissions', 'theme'],
  mixins: [permissionsMixin],
  components: {
    AssetStyled,
    Panel,
    TalpaLoaderWrapper,
    AssetInformationCard,
    MachineHealthMain,
    TyreStatusTable,
    FeatureNotAvailable,
    AssetEnginePanelsOrganism,
    ExpandableSection,
    AssetInfoStyled,
    HealthAndCountsStyled,
    AssetHealthStatusConsole,
  },
  data() {
    return {
      asset: null,
      machineHealth: [],
      isTyreNotificationAcknowledged: false,
      overallAssetHealth: '',
      assetEngines: [],
    }
  },
  mounted() {
    this.isTyreNotificationAcknowledged = Vue.localStorage.get('tyreMessageAcknowleged')
  },
  computed: {
    hasSignalReadPermission() {
      return get(this.permissions, 'read', []).find(p => p.name === 'signal_read')
    },
    hasEngineReadPermission() {
      return get(this.permissions, 'read', []).find(p => p.name === 'engine_read')
    },
    hasTyreReadPermission() {
      return get(this.permissions, 'read', []).find(p => p.name === 'tyre_read')
    },
    hasMaintenanceAppPermission() {
      return Boolean(this.hasMaintenanceAppV2Permission)
    },
    assetID() {
      return get(this.$route, 'params.id', null)
    },
    tyreNotAvailable() {
      return {
        title: this.$t('messages.tyreNotAvailableTitle'),
        message: this.$t('messages.tyreNotAvailableMsg'),
      }
    },
    NoTyreFeatureImageSrc() {
      return this.theme.isDark ? '/tyreBannerBlack.png' : '/tyreBannerWhite.png'
    },
    mappedWheelPositionsData() {
      const filterdWheelPositions = this.wheelPositions ? this.wheelPositions.filter(wheel => wheel.unmountedAt === null) : []
      const wheelPositionsData = filterdWheelPositions.map(wheelPosition => {
        const temperatureServerity = get(wheelPosition, 'temperatureThreshold.severity', '')
        const pressureThresholdSeverity = get(wheelPosition, 'pressureThreshold.severity', '')
        const tyreHealth = this.mappedSeverity(temperatureServerity, pressureThresholdSeverity)
        return tyreHealth
      })
      return wheelPositionsData
    },
    tyreHealth() {
      const health = this.healthMapper(this.mappedWheelPositionsData)
      return health
    },
    assetHealth() {
      const status = this.machineHealth[0]?.status ?? ''
      return status
    },
    combinedAssetHealth() {
      const combined = []
      combined.push(this.tyreHealth)
      combined.push(this.assetHealth)
      const health = this.healthMapper(combined)
      return health
    },
    validator() {
      return this.machineHealth !== 'Unknown' || this.machineHealth !== ''
    },
    hasSDAppPermission() {
      return Boolean(this.hasServiceDealerAppPermission)
    },
    hasUptimePermission() {
      return Boolean(this.hasUptimeServicePermission)
    },
    engine() {
      return Array.isArray(this.assetEngines?.engines) ? this.assetEngines.engines[0] : []
    },
  },
  methods: {
    acknowledgeTypeMessage() {
      Vue.localStorage.set('tyreMessageAcknowleged', true)
      this.isTyreNotificationAcknowledged = true
    },
    mappedSeverity(temperatureServerity, pressureThresholdSeverity) {
      const combinedSeverity = []
      combinedSeverity.push(temperatureServerity === '' ? 'UNKNOWN' : temperatureServerity)
      combinedSeverity.push(pressureThresholdSeverity === '' ? 'UNKNOWN' : pressureThresholdSeverity)
      if (combinedSeverity.includes('UNKNOWN')) {
        return 'Unknown'
      } else if (
        combinedSeverity.includes('CRITICAL') ||
        combinedSeverity.includes('CRITICALLY_HIGH') ||
        combinedSeverity.includes('CRITICALLY_LOW')
      ) {
        return 'Red'
      } else if (combinedSeverity.includes('HIGH') || combinedSeverity.includes('LOW')) {
        return 'Yellow'
      } else {
        return 'Green'
      }
    },
    healthMapper(healthData) {
      const combinedHealth = healthData.includes('Red')
        ? 'Red'
        : healthData.includes('Yellow')
        ? 'Yellow'
        : healthData.includes('Green')
        ? 'Green'
        : 'Unknown'
      return combinedHealth
    },
  },
  apollo: {
    asset: {
      query: ASSET_QUERY,
      variables() {
        return {
          id: this.assetID,
        }
      },
      skip() {
        return !this.assetID
      },
    },
    machineHealth: {
      query: ASSET_WITH_HEALTH_QUERY,
      variables() {
        return {
          id: this.assetID,
        }
      },
      update: data => data?.asset?.health || [],
      skip() {
        const hasPermission = Boolean(this.machineStatusRead)
        return !this.assetID || !hasPermission || this.hasMaintenanceAppPermission || this.hasSDAppPermission
      },
    },
    wheelPositions: {
      query: ASSET_WITH_WHEELPOSITION_QUERY,
      variables() {
        return {
          id: this.assetID,
        }
      },
      update: data => data?.assetWheelPositions || [],
      skip() {
        return !this.assetID || !this.hasTyreReadPermission || this.hasMaintenanceAppPermission || this.hasSDAppPermission
      },
    },
    assetEngines: {
      query: ASSET_ENGINES_QUERY,
      variables() {
        return {
          where: {
            id: this.assetID,
          },
        }
      },
      skip() {
        return !this.assetID || !this.hasUptimePermission
      },
    },
  },
}
</script>
