var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IconWrapper', [_c('TalpaIcon', {
    staticClass: "statusIcon",
    attrs: {
      "isCustomColor": true,
      "scope": 'TrafficLight',
      "name": _vm.iconName
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }