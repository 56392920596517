<template>
  <Wrapper :isMobile="isMobile">
    <SingleItem class="single-item" :isMobile="isMobile">
      <div>{{ warnings }}</div>
      <HealthYellowIcon />
      <div class="single-item-text">{{ $tc('warning', 1) }}</div>
    </SingleItem>
    <SingleItem class="single-item" :isMobile="isMobile">
      <div>{{ errors }}</div>
      <HealthRedIcon />
      <div class="single-item-text">{{ $tc('error', 1) }}</div>
    </SingleItem>
  </Wrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import HealthRedIcon from '@/assets/icons/HealthRedIcon.svg'
import HealthYellowIcon from '@/assets/icons/HealthYellowIcon.svg'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 16px;
`

const SingleItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.mediumGrey};
  font-size: 16px;
`

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    warnings: {
      type: Number,
      required: true,
    },
    errors: {
      type: Number,
      required: true,
    },
  },
  components: {
    Wrapper,
    SingleItem,
    HealthRedIcon,
    HealthYellowIcon,
  },
}
</script>
