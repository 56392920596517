var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainWrapper', [_c('WarningErrorCounter', {
    attrs: {
      "warnings": _vm.warningsErrorsCount.warnings,
      "errors": _vm.warningsErrorsCount.errors
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }