var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ExpandToggleRow', [_c('ExpandToggleActiveArea', {
    on: {
      "click": _vm.toggleExpanding
    }
  }, [_c('ExpandToggleArrowWrapper', {
    attrs: {
      "isExpanded": _vm.isExpanded
    }
  }, [_c('ChevronDownIcon', {
    staticClass: "expand-icon"
  })], 1), _c('span', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('span', {
    staticClass: "subtitle"
  }, [_vm._t("subtitle")], 2)])], 1)], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isExpanded ? _vm._t("content") : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }