<template>
  <MainWrapper>
    <WarningErrorCounter :warnings="warningsErrorsCount.warnings" :errors="warningsErrorsCount.errors" />
  </MainWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import WarningErrorCounter from './WarningErrorCounter'

const MainWrapper = styled('div')`
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  padding: 20px;
`
export default {
  inject: ['uiSettings'],
  props: {
    machineHealth: {
      type: Array,
      required: true,
    },
  },
  components: {
    MainWrapper,
    WarningErrorCounter,
  },
  computed: {
    warningsErrorsCount() {
      const allNotifications = this.machineHealth?.reduce(
        (acc, { activeNotifications }) => (Array.isArray(activeNotifications) ? [...acc, ...activeNotifications] : acc),
        [],
      )
      return allNotifications.reduce(
        (acc, notification) => {
          if (notification.severity === 'Red') {
            return {
              ...acc,
              errors: acc.errors + 1,
            }
          }
          if (notification.severity === 'Yellow') {
            return {
              ...acc,
              warnings: acc.warnings + 1,
            }
          }
          return acc
        },
        { warnings: 0, errors: 0 },
      )
    },
  },
}
</script>
