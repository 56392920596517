var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Wrapper', {
    attrs: {
      "isMobile": _vm.isMobile
    }
  }, [_c('SingleItem', {
    staticClass: "single-item",
    attrs: {
      "isMobile": _vm.isMobile
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.warnings))]), _c('HealthYellowIcon'), _c('div', {
    staticClass: "single-item-text"
  }, [_vm._v(_vm._s(_vm.$tc('warning', 1)))])], 1), _c('SingleItem', {
    staticClass: "single-item",
    attrs: {
      "isMobile": _vm.isMobile
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.errors))]), _c('HealthRedIcon'), _c('div', {
    staticClass: "single-item-text"
  }, [_vm._v(_vm._s(_vm.$tc('error', 1)))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }