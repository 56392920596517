var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.status !== 'Unknown' ? _c('Wrapper', {
    attrs: {
      "isMobile": _vm.isMobile
    }
  }, [_c('TrafficLightIcon', {
    attrs: {
      "iconName": _vm.status
    }
  }), _c('TitleWrapper', {
    attrs: {
      "status": _vm.status
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('health')) + ":")]), _c('span', {
    staticClass: "status"
  }, [_vm._v(_vm._s(_vm.healthStatus))])])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }