<template>
  <div>
    <ExpandToggleRow>
      <ExpandToggleActiveArea @click="toggleExpanding">
        <ExpandToggleArrowWrapper :isExpanded="isExpanded">
          <ChevronDownIcon class="expand-icon" />
        </ExpandToggleArrowWrapper>
        <span class="title">
          {{ title }} <span class="subtitle"><slot name="subtitle" /></span>
        </span>
      </ExpandToggleActiveArea>
    </ExpandToggleRow>
    <transition name="fade">
      <slot name="content" v-if="isExpanded" />
    </transition>
  </div>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronDownIcon } from 'vue-feather-icons'

const ExpandToggleRow = styled('div')`
  display: flex;
  align-items: center;
`

const ExpandToggleActiveArea = styled('div')`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.atomic.textMain};
  font-size: 30px;
  cursor: pointer;
`

const ExpandToggleArrowWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.atomic.primary};
  background: ${({ theme }) => theme.colors.atomic.chartCardBG};

  & > .expand-icon {
    ${({ isExpanded }) => isExpanded && 'transform: rotate(180deg)'};
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    ExpandToggleRow,
    ExpandToggleActiveArea,
    ExpandToggleArrowWrapper,
    ChevronDownIcon,
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  methods: {
    toggleExpanding() {
      this.isExpanded = !this.isExpanded
    },
  },
}
</script>
