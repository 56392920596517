<template>
  <Wrapper :isMobile="isMobile" v-if="status !== 'Unknown'">
    <TrafficLightIcon :iconName="status" />
    <TitleWrapper :status="status">
      <span>{{ $t('health') }}:</span>
      <span class="status">{{ healthStatus }}</span>
    </TitleWrapper>
  </Wrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import TrafficLightIcon from '../Atoms/TrafficLightIcon'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background: ${({ theme }) => (theme.isDark ? theme.colors.talpaGrey : theme.colors.atomic.white)};
  border-radius: 0.5rem;
  gap: 8px;
`
const TitleWrapper = styled('div')`
  color: ${({ theme }) => theme.colors.mediumGrey};
  font-size: 16px;
  display: flex;
  justify-content: space-evenly;
  width: 140px;
  .status {
    color: ${({ theme, status }) =>
      status === 'Green'
        ? theme.colors.atomic.statusGreen
        : status === 'Yellow'
        ? theme.colors.atomic.statusYellow
        : theme.colors.atomic.statusRed};
  }
`
export default {
  inject: ['theme'],
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      validator: function (value) {
        return ['Green', 'Yellow', 'Red', 'Unknown'].indexOf(value) !== -1
      },
    },
  },
  components: {
    Wrapper,
    TitleWrapper,
    TrafficLightIcon,
  },
  computed: {
    healthStatus() {
      return this.status ? `${this.$tc('assetHealthStatus.' + this.status)}` : ''
    },
  },
}
</script>
